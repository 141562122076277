import './member.css';
import axios from "axios";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { getYear } from 'date-fns';
import { Space } from 'antd';

import {IconRenderer, imagePath, Spinner} from "../util";
import { useLinksContext } from './../LinkContext';

// eslint-disable-next-line
import log from 'loglevel';

export function Member() {
    const memberId = useParams().memberId;
    const [member, setMember] = useState(undefined);
    const [loaded, setLoaded] = useState(false);
    const links = useLinksContext();
    

    useEffect(() => {
        document.title = "Numento - " + member?.Name;
    });

    useEffect(() => {
        axios.get(`/items/members/${memberId}`)
            .then(response => {
                console.log("response", response);
                setMember(response.data.data);
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    }, [memberId]);

    if (!member) {
        return Spinner();
    }

    var imagePlacementClass = "memberLeft";
    if (member.image_placement === "Right") {
        imagePlacementClass = "memberRight";
    }


    // If you have a Date object
    const date = new Date(member?.Joined);
    const year = getYear(date);

    return (
        <div className="memberPage">
            <div className={imagePlacementClass}>
                <div>
                    <div className="memberPicture">
                        <img src={imagePath(member?.image)} alt={member?.Name} onLoad={() => setLoaded(true)}/>
                    </div>
                    <div className='memberSocial'>
                        <Space size="large" className="icons">
                            {member?.links?.map((mainLink, index) => {
                                const linkItem = links.find(link => link.id === mainLink);
                                return (
                                    <a key={index} href={linkItem?.link} target="_blank" rel="noopener noreferrer">
                                        {IconRenderer(linkItem)}
                                    </a>
                                )
                            })}
                        </Space>                                
                    </div>
                </div>

                <div>
                    <div className='memberInfo'>
                        <h2>{member?.Name}</h2>
                        <h2>{member?.instrument}</h2>
                        <p>Joined Numento: {year}</p>
                    </div>
                    <div className='memberStory'>
                        <Markdown remarkPlugins={[remarkGfm]}>{member?.introduction}</Markdown>                     
                    </div>
                </div>
            </div>
        </div>
    );
}
